import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { Helmet } from "react-helmet";
import ScrollToTop from 'react-router-scroll-top'

import Footer from "./Components/Footer";
import LetsChat from "./Components/LetsChat";
import Navigation from "./Components/Navigation";

const About = React.lazy(() => import("./Components/Pages/About"))
const Home = React.lazy(() => import("./Components/Pages/Home"))
const Contact = React.lazy(() => import("./Components/Pages/Contact"))
const Services = React.lazy(() => import("./Components/Pages/Services"))
const Partners = React.lazy(() => import("./Components/Pages/Partners"))
const Functions = React.lazy(() => import("./Components/Pages/Functions"))
const Industry = React.lazy(() => import("./Components/Pages/Industry"))
const Privacy = React.lazy(() => import("./Components/Pages/Privacy"))
const Terms = React.lazy(() => import("./Components/Pages/Terms"))
const NotFound = React.lazy(() => import('./Components/NotFound'))

export default function App() {
  return (
    <>
      

      {/* <Suspense fallback={<div className="text-center" style={{ marginTop: "200px", marginBottom: "200px" }}>Loading...</div>}> */}
      <Suspense fallback="">
        <Router >
          <ScrollToTop>
            <Navigation />
            <Switch>
              <Route path="/about/" component={About} />
              <Route path="/services/" component={Services} />
              <Route path="/partners/" component={Partners} />
              <Route path="/contact/" component={Contact} />
              <Route path="/functions/" component={Functions} />
              <Route path="/industry/" component={Industry} />
              <Route path="/privacy/" component={Privacy} />
              <Route path="/terms/" component={Terms} />
              <Route path="/" exact component={Home} />
              <Route path="*" component={NotFound} />
            </Switch>

            <LetsChat />
            <Footer />

          </ScrollToTop>
        </Router>
      </Suspense>


    </>
  );
}