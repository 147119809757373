import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { NavLink } from 'react-router-dom'
import LogoIMG from '../img/logo.svg'

const Navigation = () => {
  const [navBG, setNavBG] = useState();
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    const handleOnScroll = () => {
      activeMenu ? setNavBG(100) : setNavBG(window.scrollY / 50)
    }
    document.addEventListener("scroll", handleOnScroll, { passive: true })
    return () => {
      // This cleans up the event handler when the component unmounts.
      document.removeEventListener("scroll", handleOnScroll)
    }
  }, [activeMenu])


  return (
    <Container fluid className="fixed-top py-2" style={{ backgroundColor: `rgba(255,255,255,${navBG})` }}>
      <Container className="px-0">
        <Navbar collapseOnSelect expand="lg" bg="" variant="light">
          <NavLink to="/" className="border-0"><img src={LogoIMG} alt="SolveBI - Data Integration &amp; Visualisation" width="150" height="40" className="mr-4" /></NavLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => {
            setActiveMenu(!activeMenu)
            setNavBG(100)
          }} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavLink className="nav-link" to="/about/" >About</NavLink>
              <NavLink className="nav-link" to="/services/">What we do</NavLink>
              <NavLink className="nav-link" to="/functions/">Functions</NavLink>
              <NavLink className="nav-link" to="/industry/">Industries</NavLink>
              <NavLink className="nav-link" to="/partners/">Partners</NavLink>
            </Nav>
            <Nav>
              <NavLink className="nav-link" to="/contact">Contact</NavLink>
              <a href="tel:1300-509-976" className={`ml-2 btn ${navBG > .2 ? "btn-primary" : ""}`}><strong>1300 509 976</strong></a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Container>

  );
};

export default Navigation;
